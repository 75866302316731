class window.Header
  constructor: ->
    @menuButton = $('.js-menu-button')
    @headerMenu = $('.header-menu')
    @feedbackButton = $('.base-white-feedback-button')
    @searchButton = $('.base-search-button')
    @headerFeedback = $('.header-feedback')
#    @bookButton = $('.js-get-book')
    @bookFeedback = $('.book-feedback')

    @init()

  disableScrollPage: ->
    if $('html, body').css('overflow-y') == 'hidden'
      $('html, body').css('overflow-y', 'auto')
    else
      $('html, body').css('overflow-y', 'hidden')

  $(window).on 'scroll':->
    topHeight = $(window).scrollTop();
    if topHeight > 100 
      $('.header-navigation__buttons-block > button').addClass('menu-button--min')
      $('.language-item').addClass('menu-button--min')
    else
      $('.header-navigation__buttons-block > button').removeClass('menu-button--min')
      $('.language-item').removeClass('menu-button--min')

  init: ->
    self = this

    self.initLogo()
    self.initLanguageSelector()

    @menuButton.click ->
      self.toggleMenu()
      self.toggleColorTheme()

    @searchButton.click ->
      @searchButton.toggleClass('base-search-button--active')
    
    @feedbackButton.click ->
      self.toggleFeedback('header')

    $('html body').on 'click', '.js-get-book', ->
      self.toggleFeedback('book')

  toggleMenu: ->
    @headerMenu.toggleClass('header-menu--active')
    @disableScrollPage()
    @toggleMenuIcon()
    @toggleVisibleSearchButton()
    @toggleLogo()

  toggleColorTheme: ->
    $('.header__navigation').toggleClass('header__navigation--white')
    $('.header__navigation').toggleClass('header__navigation--black')

  toggleMenuIcon: ->
    @menuButton.toggleClass('menu-button--active')
    
  toggleVisibleSearchButton: -> 
    @searchButton.toggleClass('base-search-button--dissactive')

  toggleLogo: ->
    $('.header__logo').toggleClass('header__logo--in-menu')

  toggleFeedback: (type) ->
    @toggleVisibleButtonForFeedback()
    @disableScrollPage()
    @toggleLogo()
    @toggleFeedbackButton()

    if (@bookFeedback.hasClass('book-feedback--active'))
      @bookFeedback.removeClass('book-feedback--active')
      return
    $('.' + type + '-feedback').toggleClass(type + '-feedback--active')

  toggleVisibleButtonForFeedback: ->
    @searchButton.toggleClass('dissactive')
    @menuButton.toggleClass('dissactive')

  toggleFeedbackButton: -> 
    @feedbackButton.toggleClass('base-white-feedback-button--active')

  initLanguageSelector: ->
    self = this

    $('.header__language-selector').mouseenter (event) ->
      if (self.isDesktop())
        $(this).addClass('header__language-selector--active');

    $('.header__language-selector').mouseleave (event) ->
      if (self.isDesktop())
        $(this).removeClass('header__language-selector--active');

    $('.header__language-selector').click (event) ->
      if (self.isDesktop() == false)
        $(this).toggleClass('header__language-selector--active');

    $(window).on 'scroll':->
      if (self.isDesktop() == false)
        $('.header__language-selector').removeClass('header__language-selector--active');

    $(window).on 'click': (event) ->
      if (self.isDesktop() == false)
        if ($(event.target).closest('.header__language-selector, .header__language-selector--active').length)
          return
        else
          $('.header__language-selector').removeClass('header__language-selector--active');

  isDesktop: ->
    if ($(window).innerWidth() >= '1024')
      return true
    else
      return false

  initLogo: ->
    self = this
    bgArray = self.getBackgroundsArray()
    bgArrayIndex = 0

    if (bgArray.length)
      $('.header__logo').css('background', bgArray[bgArrayIndex][1]);
      $('.header__logo').addClass('header__logo--in-' + bgArray[bgArrayIndex][2] + '-background');

    $(window).on 'resize':->
      bgArray = self.getBackgroundsArray()

    $(window).on 'scroll':->
      topHeight = $(window).scrollTop();

      if topHeight > 100
        $('.header__logo').addClass('header__logo--min');
      else if topHeight < 100
        $('.header__logo').removeClass('header__logo--min');

      if (bgArray.length)
        if bgArrayIndex + 1 < bgArray.length && topHeight - bgArray[bgArrayIndex][0] - 30 > 0
          $('.header__logo').removeClass('header__logo--in-white-background');
          $('.header__logo').removeClass('header__logo--in-blue-background');
          bgArrayIndex++
          $('.header__logo').addClass('header__logo--in-' + bgArray[bgArrayIndex][2] + '-background');
          $('.header__logo').css('background', bgArray[bgArrayIndex][1]);

        if bgArrayIndex > 0 && topHeight - bgArray[bgArrayIndex][0] - 30 < 0
          $('.header__logo').removeClass('header__logo--in-white-background');
          $('.header__logo').removeClass('header__logo--in-blue-background');
          bgArrayIndex--
          $('.header__logo').addClass('header__logo--in-' + bgArray[bgArrayIndex][2] + '-background');
          $('.header__logo').css('background', bgArray[bgArrayIndex][1]);

  getColorBrightness: (color) ->
    r = undefined
    b = undefined
    g = undefined
    hsp = undefined
    a = color
    if a.match(/^rgb/)
      a = a.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
      r = a[1]
      g = a[2]
      b = a[3]
    else
      a = +('0x' + a.slice(1).replace(a.length < 5 and /./g, '$&$&'))
      r = a >> 16
      b = a >> 8 & 255
      g = a & 255

    hsp = Math.sqrt(0.299 * r * r + 0.587 * g * g + 0.114 * b * b)

    if hsp > 127.5 || hsp == 0
      # light background
#      return 'header__logo--in-white-background'
      return 'white'
    else
      # dark background
      return 'blue'

  getBackgroundsArray: ->
    self = this
    array = new Array()

    $('.js-logo-background-fixator').each ->
      block = $(this)
      color = block.css('background-color')

      if color == 'rgba(0, 0, 0, 0)' || color == 'rgba(0, 0, 0)'
        color = 'rgb(255, 255, 255)'

      array.push([block.offset().top, color, self.getColorBrightness(color)])

    return array