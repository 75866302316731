require('jquery');
require('bootstrap-sass-loader!./bootstrap/bootstrap-sass_first.config.js');
require('./app/fonts')
Modernizr = require('modernizr');

require('./app/header.coffee');
require('./app/feedback_form.coffee');
require('./app/agreement-form.coffee');
require("./app/book_feedback_form.coffee");


$(document).ready ->
  new window.Header()
  new window.BookFeedbackForm()
  new window.FeedbackForm()
  new window.AgreementForm()
