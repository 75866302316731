class window.AgreementForm
  constructor: ->
    @agreeButton = $('.data-agreement__content-button button')
    @agreementBlock = $('.data-agreement')

    @init()

  init: ->
    self = @

    if (@getCookie('jurproject__data-agreement') != 'agree')
      @agreementBlock.removeClass('data-agreement__hide')

    @agreeButton.click ->
      self.agreementBlock.addClass('data-agreement__hide')
      self.setCookie('jurproject__data-agreement', 'agree', {path: '/'})

  getCookie: (name) ->
    matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"))
    return  if matches then decodeURIComponent(matches[1]) else undefined

  setCookie: (name, value, options = {}) ->
    options = { path: '/' }

    if options.expires instanceof Date
      options.expires = options.expires.toUTCString()

    updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for optionKey in options
      updatedCookie += "; " + optionKey
      optionValue = options[optionKey]
      if optionValue != true
        updatedCookie += "=" + optionValue

    document.cookie = updatedCookie