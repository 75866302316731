require('jquery-mask-plugin');

class window.FeedbackForm
  constructor: ->
    self = this

    @initForm()
    @initSubmitForm()
    @initGratitude()
    @initTextarearesize()

  initForm: ->
    $('.get-feedback__input-container').click (event) ->
      event.preventDefault();
      $(this).find('input, textarea').focus();
      $(this).addClass('get-feedback__input-container--active');

    $(document).mouseup (e) ->
      container = $('.get-feedback__input-container--active')
      containerValueBoolean = container.find($('.get-feedback__input')).val() == ''
      if container.has(e.target).length == 0 and containerValueBoolean
        container.removeClass('get-feedback__input-container--active');
      return

    $('input[name="PHONE_PHONE"]').mask('+7 (999) 999-9999');

    # обработка формы
  initSubmitForm: ->
    $(".js-feedback-form-inner").each ->
      unless $(this).attr('data-init') == 'true'
        $(this).attr('data-init', true)
        $(this).submit (event) ->
          window.initSubmitFormFlag = true
          event.preventDefault()
          self = $(this)
          data = self.serialize()
          $.ajax
            url: $(this).attr('action')
            data: data
            dataType: 'html'
            method: 'post'
            error: (error) ->
              $('.error-text').css('display','none');
              $('.text').css('display','block');
              $('.get-feedback__input-container').removeClass 'error'
              $('.get-feedback__checkbox-container').removeClass 'error'
              errors = $.parseJSON(error.responseText)
              $.each errors.ERROR, (key, value) ->
                $('input[name="' + key + '"]').parents('.get-feedback__input-container').addClass 'error get-feedback__input-container--active'
                $('input[name="' + key + '"]').parents('.get-feedback__input-wrap').find('.error-text').html(value).css('display','block');
                $('input[name="' + key + '"]').parents('.get-feedback__input-wrap').find('.text').css('display','none');
                $('input[name="' + key + '"]').parents('.get-feedback__checkbox-container').addClass 'error'

            success: (result) ->
              $('.get-feedback__input-container').removeClass 'error'
              $('.get-feedback__checkbox-container').removeClass 'error'
              $('.error-text').css('display','none');
              $('.text').css('display','block');
              $('.popup-gratitude').addClass('popup-gratitude--active');

  initGratitude: ->
    $('.gratitude__close-button').click (event) ->
      $('.popup-gratitude').removeClass('popup-gratitude--active');

    $('.popup-gratitude__wrapper').click (event) ->
      if $(event.target).closest('.popup-gratitude__content').length
        return
      $('.popup-gratitude').removeClass('popup-gratitude--active');


# Autoresize textarea
  initTextarearesize: ->
    $('textarea').each(->
      @setAttribute 'style', 'height:' + 54 + 'px;overflow-y:hidden;max-height:79px;'
      return
    ).on 'input', ->
      @style.height = @scrollHeight + 'px'
      return
